<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
    label: String,
})
</script>

<template>
    <span v-if="props.label" class="nb-layout-sidebar__menu-tooltip">{{ props.label }}</span>
</template>

<style lang="scss" scoped>
@import '@/assets/abstracts/_colors.scss';

span {
    display: inline-block;
    padding: 0.75rem 1rem;
    background: $sidebarBGColor;
    position: relative;
    width: max-content;
    box-shadow:
        0 0 4px 0 #FFFFFF40 inset,
        0 4px 5px 0 #00000024;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        border-right: 8px solid $sidebarActiveBGColor;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
}
</style>